






















































































































import SuperAdminGuidanceUploadDialog from '@/views/SuperAdminViews/super-admin-guidance/SuperAdminGuidanceUploadDialog.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { TGuidance } from '@/api/schema';
import { deleteGuidance } from '@/api/CopyStake';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import type { AxiosError } from 'axios';
import GuidanceMixin from '@/mixins/guidance/GuidanceMixin';

export default {
  name: 'SuperAdminGuidanceView',
  components: {
    ConfirmationDialog,
    SuperAdminGuidanceUploadDialog
  },
  mixins: [GuidanceMixin],
  data: (): any => ({
    dialogs: {
      uploadDialog: false,
      confirmationDialog: false,
      isLoading: false,
      data: null
    }
  }),
  methods: {
    handlerOpenGuidanceUploadDialog(guidance: TGuidance | null): void {
      this.dialogs.data = guidance || null;
      this.dialogs.uploadDialog = true;
    },
    handlerOnCloseGuidanceUploadDialog(): void {
      this.dialogs.uploadDialog = false;
      this.dialogs.data = null;
    },
    handlerOpenDeleteGuidanceDialog(guidance: TGuidance): void {
      this.dialogs.confirmationDialog = true;

      this.dialogs.data = {
        header: 'Delete File',
        disclaimer: `
          <span>
            Are you sure you want to delete file <strong>${guidance.title}</strong>?
          </span>`,
        okText: 'Delete',
        handler: () => this.deleteGuidance(guidance.id)
      };
    },
    async deleteGuidance(guidanceId: number): Promise<void> {
      this.dialogs.isLoading = true;

      await deleteGuidance(guidanceId)
        .then(() => {
          this.getGuidanceList();
          this.dialogs.confirmationDialog = false;

          this.$toast.success(`The file was successfully deleted`);
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.dialogs.isLoading = true;
        });
    }
  }
};

































































import { PropType } from 'vue';
import BaseDialog from '@/components/BaseDialog.vue';
import * as vr from '@/helpers/validation';
import { TGuidance, ValidationRules } from '@/api/schema';
import { objectToBlob, urlToBlob } from '@/helpers/blobHelper';
import { updateGuidance, uploadGuidance } from '@/api/CopyStake';
import { errorToastMessage } from '@/helpers/errorToastMessage';

const maxFileSize = 20 * 1024 * 1024; //20MB
const ACCEPTED_TYPE = [
  'image/webp',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export default {
  name: 'SuperAdminGuidanceUploadDialog',
  components: {
    BaseDialog
  },
  props: {
    value: {
      type: Boolean,
      default: (): boolean => false
    },
    guidance: {
      type: Object as PropType<TGuidance>,
      default: null
    }
  },
  data(): any {
    return {
      formData: {
        title: '',
        uploadFile: null
      },
      isLoading: false,
      isLocalFileLoading: false,
      isFormValid: false,
      maxFileSize,
      ACCEPTED_TYPE
    };
  },
  watch: {
    async value(): Promise<void> {
      await this.resetForm();

      if (this.guidance) {
        this.formData.title = this.guidance.title;
        await this.setLocalFileFromUrl(this.guidance.url);
      }
    }
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    rules(): ValidationRules {
      return {
        title: [(v: string) => vr.required(v, 'File name')],
        file: [
          (v: string) => vr.required(v, 'File'),
          (v) => vr.fileMaxSize(v, maxFileSize, 'File size – up to 20MB')
        ]
      };
    },
    isEdit(): boolean {
      return !!this.guidance;
    },
    hasError(): boolean {
      if (!this.$refs?.fileInput) return false;

      return !this.$refs?.fileInput?.valid;
    }
  },
  methods: {
    async setLocalFileFromUrl(url: string): Promise<void> {
      try {
        this.isLocalFileLoading = true;

        this.formData.uploadFile = null;

        const blob = await urlToBlob(url);
        blob.name = url.split('/').pop() || '';

        this.formData.uploadFile = blob;
      } catch (e) {
        errorToastMessage(e);
      } finally {
        this.isLocalFileLoading = false;
      }
    },
    async handlerOnSubmitForm(): Promise<void> {
      try {
        await this.$refs.form.validate();

        if (!this.isFormValid) return;

        this.isLoading = true;

        const formData = new FormData();

        formData.append(
          'guidance',
          objectToBlob({
            title: this.formData.title
          })
        );
        formData.append('file', this.formData.uploadFile);

        this.isEdit
          ? await updateGuidance(this.guidance.id, formData)
          : await uploadGuidance(formData);

        this.$toast.success(
          `The file was successfully ${this.isEdit ? 'updated' : 'uploaded'}.`
        );

        this.isLoading = false;
        this.closeDialog();
        this.$emit('submit');
      } catch (error) {
        errorToastMessage(error);
      } finally {
        this.isLoading = false;
      }
    },
    handlerOnClearFile(): void {
      this.formData.uploadFile = null;
    },
    closeDialog(): void {
      if (this.isLoading) return;

      this.$emit('close');
      this.showDialog = false;
    },
    async resetForm(): Promise<void> {
      await this.$refs.form?.reset();

      this.formData = {
        uploadFile: null,
        title: ''
      };
    }
  }
};

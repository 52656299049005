var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"guidance-upload",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"text-h5 font-weight-medium"},[_vm._v("Guidance")])])],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":1}},[_c('v-row',[_c('v-col',{staticClass:"pb-4"},[_c('h2',{staticClass:"pb-2 text-lg font-weight-semibold"},[_vm._v("These materials can help casino operators:")]),_c('v-list',{staticClass:"pa-0"},_vm._l((_vm.helpMaterialsList),function(item){return _c('v-list-item',{key:item.text,staticClass:"pl-0"},[_c('div',{staticClass:"guidance-upload__help-icon mr-3"},[(item.icon)?_c('v-icon',{attrs:{"color":"#50535A","size":"20"}},[_vm._v(_vm._s(item.icon))]):_c(item.component,{tag:"component"})],1),_c('span',[_vm._v(_vm._s(item.text))])])}),1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex justify-space-between pb-4"},[_c('div',[_c('h2',{staticClass:"pb-1 text-lg font-weight-semibold"},[_vm._v("Quick Links")]),_c('div',{staticClass:"text-sm secondary--text"},[_c('div',[_vm._v("Please note that the sections and the files you download will be provided in the same order they are displayed within the operator interface.")]),_c('div',[_vm._v("To ensure proper organization, make sure to verify the sequence of files before initiating the download process.")])])]),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"success"},on:{"click":function($event){return _vm.handlerOpenGuidanceUploadDialog()}}},[_vm._v("Add File +")])],1),_c('v-list',[(_vm.isLoading)?_c('v-row',_vm._l((_vm.guidanceList.length || 5),function(i){return _c('v-col',{key:i,staticClass:"pa-2",attrs:{"cols":"4"}},[_c('v-list-item',{staticClass:"guidance-upload__loading-item",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',{staticClass:"text-body-1"},[_c('v-skeleton-loader',{staticClass:"guidance-upload__loader",attrs:{"type":"list-item-avatar"}})],1)]}}],null,true)})],1)}),1):_c('v-row',_vm._l((_vm.guidanceList),function(guidance,index){return _c('v-col',{key:guidance.id,staticClass:"pa-2",attrs:{"cols":"4"}},[_c('v-list-item',{staticClass:"guidance-upload__link-item",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',{staticClass:"text-body-1"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"guidance-upload__link-item-index"},[_vm._v(_vm._s(index + 1))]),_c('span',[_vm._v(_vm._s(guidance.title))])])]),_c('v-icon',{on:{"click":function($event){return _vm.openLink(guidance)}}},[_vm._v("mdi-open-in-new")]),_c('v-menu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","text":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.handlerOpenGuidanceUploadDialog(guidance)}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-pencil-outline")]),_vm._v("Edit")],1),_c('v-list-item',{staticClass:"d-flex align-center cursor-pointer",on:{"click":function($event){return _vm.handlerOpenDeleteGuidanceDialog(guidance)}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-delete-outline")]),_vm._v("Delete")],1)],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1)],1)],1)],1),_c('SuperAdminGuidanceUploadDialog',{attrs:{"guidance":_vm.dialogs.data},on:{"submit":_vm.getGuidanceList,"close":_vm.handlerOnCloseGuidanceUploadDialog},model:{value:(_vm.dialogs.uploadDialog),callback:function ($$v) {_vm.$set(_vm.dialogs, "uploadDialog", $$v)},expression:"dialogs.uploadDialog"}}),_c('ConfirmationDialog',{attrs:{"processing":_vm.dialogs.isLoading,"data":_vm.dialogs.data,"closable":""},on:{"close":function () { return _vm.dialogs.confirmationDialog = false; }},model:{value:(_vm.dialogs.confirmationDialog),callback:function ($$v) {_vm.$set(_vm.dialogs, "confirmationDialog", $$v)},expression:"dialogs.confirmationDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
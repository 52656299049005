import { render, staticRenderFns } from "./SuperAdminGuidanceView.vue?vue&type=template&id=750d7f26&lang=pug"
import script from "./SuperAdminGuidanceView.vue?vue&type=script&lang=ts"
export * from "./SuperAdminGuidanceView.vue?vue&type=script&lang=ts"
import style0 from "./SuperAdminGuidanceView.vue?vue&type=style&index=0&id=750d7f26&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VCard,VCol,VContainer,VIcon,VList,VListItem,VListItemContent,VMenu,VRow,VSkeletonLoader})
